<template>
  <div class="w-full md:w-10/12 overflow-x-auto">
    <ul class="bg-white p-4 text-primary font-proximaMedium rounded-sm flex items-center">
      <li class="w-3/12">Vaccine</li>
      <li class="w-3/12">Diagnostic Clinic</li>
      <li class="w-1/12">Applied</li>
      <li class="w-1/12">Expires</li>
      <li class="w-4/12">Proof</li>
    </ul>
    <ul v-for="(data, i) in vaccinations" :key="i"
      class="py-6 border-b border-primary text-sm font-proximaLight flex items-center justify-between"
      :class="{ 'text-lightGrey': !data.vaccine_evidence }">
      <li class="w-3/12 px-2 flex items-center justify-between">
        {{ data.vaccine_name }}
        <img v-if="data.is_verified" src="@/assets/images/approval-shield-icon.png" alt="approval-shield-icon" />
      </li>
      <li v-if="diagnosticPic" class="w-3/12 text-primary flex items-center">
        <!--  <img v-if="diagnosticPic" class="pr-1" :src="require('@/assets/images/pp-1.png')" :alt="diagnosticPic" /> -->
        <p> {{ data.diagnosis_clinic }}
          <br />
          <span class="text-darkGrey">{{ data.clinic_email_address }}</span>
        </p>
      </li>
      <li v-else class="w-3/12 text-left flex flex-col">
        {{ data.diagnosis_clinic }}
        <span class="text-xs">{{ data.clinic_email_address }}</span>
      </li>
      <li class="w-1/12">{{ data.date_applied ? data.date_applied.substr(0, 10) : "" }}</li>
      <li class="w-1/12">{{ data.date_expired ? data.date_expired.substr(0, 10) : "" }}</li>
      <li class="w-4/12 relative" :class="{ 'text-primary': data.vaccine_evidence }">
        <!--  {{ data.vaccine_evidence }}  -->

        <CaresheetsIcon v-if="data.vaccine_evidence" iconColor="#0AC0CB" bgColor="#fff" width="26" height="26"
          class="w-3/4 cursor-pointer absolute right-20" @click.native="showEvidanceSubmit(data.vaccine_evidence)" />
        <TrashIcon v-if="!data.is_verified" iconColor="#0AC0CB" bgColor="#fff" width="26" height="26"
          class="cursor-pointer absolute right-4 -top-1" @click.native="deleteSubmit(data.id)" />
      </li>
    </ul>

    <AddIcon @click.native="gotoAddVaccine" class="mt-8 cursor-pointer" width="62" height="62" />
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import CaresheetsIcon from "../../icons/CaresheetsIcon.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TrashIcon,
    AddIcon,
    CaresheetsIcon,
  },

  data() {
    return {
      pet: {},
      diagnosticPic: "pp-1",
    };
  },

  computed: {
    ...mapGetters({
      getError: "medicalhistory/getError",
      vaccinations: "medicalhistory/getVaccinations",
    }),
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    this.pet = JSON.parse(retrievedObject);
    this.getVaccinationOfPet(this.pet.petID);
  },

  methods: {
    ...mapActions({
      getVaccinationOfPet: "medicalhistory/getVaccinationOfPet",
      deleteVaccination: "medicalhistory/deleteVaccination",
    }),

    gotoAddVaccine() {
      this.$router.push({ path: "/addvaccination" });
    },

    deleteSubmit(id) {
      this.deleteVaccination(id);
    },
    showEvidanceSubmit(vaccine_evidence) {
      window.open(vaccine_evidence, "_blank");

    }
  },
};
</script>

<style></style>