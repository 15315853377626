<template>
  <div class="my-6">
    <VaccinationSection />
  </div>
</template>

<script>
import VaccinationSection from "../components/vaccination/VaccinationSection.vue";

export default {
  components: {
    VaccinationSection,
  },
};
</script>